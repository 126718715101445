import { useEffect, useState } from 'react';

export function useServerUrl(region?: string) {
  const [serverUrl, setServerUrl] = useState<string | undefined>();
  useEffect(() => {
    let endpoint = `/api/url`;
    if (region) {
      endpoint += `?region=${region}`;
    }
    fetch(endpoint).then(async (res) => {
      if (res.ok) {
        const body = await res.json();
        console.log(body);
        setServerUrl(body.url);
      } else {
        throw Error('Error fetching server url, check server logs');
      }
    });
  });
  return serverUrl;
}

export function encodePassphrase(passphrase: string) {
  return encodeURIComponent(passphrase);
}

export function decodePassphrase(base64String: string) {
  return decodeURIComponent(base64String);
}

export function generateRoomId(): string {
  // return `${randomString(4)}-${randomString(4)}`;
return `agents-1234`;
}

export function randomString(length: number): string {
  let result = '';
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const getLanguageCode = (language: string): string => {
  const languageMap: { [key: string]: string } = {
    'Bulgarian': 'bg',
    'Catalan': 'ca',
    'Chinese (Mandarin, Simplified)': 'zh',
    'Czech': 'cs',
    'Danish': 'da',
    'Dutch': 'nl',
    'English': 'en',
    'Estonian': 'et',
    'Finnish': 'fi',
    'Flemish': 'nl',
    'French': 'fr',
    'German': 'de',
    'Greek': 'el',
    'Hindi': 'hi',
    'Hungarian': 'hu',
    'Indonesian': 'id',
    'Italian': 'it',
    'Japanese': 'ja',
    'Korean': 'ko',
    'Latvian': 'lv',
    'Lithuanian': 'lt',
    'Malay': 'ms',
    'Norwegian': 'no',
    'Polish': 'pl',
    'Portuguese': 'pt',
    'Romanian': 'ro',
    'Russian': 'ru',
    'Slovak': 'sk',
    'Spanish': 'es',
    'Swedish': 'sv',
    'Thai': 'th',
    'Turkish': 'tr',
    'Ukrainian': 'uk',
    'Vietnamese': 'vi',
    'Afrikaans': 'af',
    'Arabic': 'ar',
    'Armenian': 'hy',
    'Azerbaijani': 'az',
    'Belarusian': 'be',
    'Bosnian': 'bs',
    'Croatian': 'hr',
    'Galician': 'gl',
    'Hebrew': 'he',
    'Icelandic': 'is',
    'Kannada': 'kn',
    'Kazakh': 'kk',
    'Macedonian': 'mk',
    'Marathi': 'mr',
    'Maori': 'mi',
    'Nepali': 'ne',
    'Persian': 'fa',
    'Serbian': 'sr',
    'Slovenian': 'sl',
    'Swahili': 'sw',
    'Tagalog': 'tl',
    'Tamil': 'ta',
    'Urdu': 'ur',
    'Welsh': 'cy'
  };

  return languageMap[language] || 'en-IN'; // Default to 'en-IN' if not found
};

export const isWhisperLanguage = (language: string): boolean => {
  const whisperLanguages: { [key: string]: string } = {
    'af': 'Afrikaans',
    'ar': 'Arabic',
    'hy': 'Armenian',
    'az': 'Azerbaijani',
    'be': 'Belarusian',
    'bs': 'Bosnian',
    'hr': 'Croatian',
    'gl': 'Galician',
    'he': 'Hebrew',
    'is': 'Icelandic',
    'kn': 'Kannada',
    'kk': 'Kazakh',
    'mk': 'Macedonian',
    'mr': 'Marathi',
    'mi': 'Maori',
    'ne': 'Nepali',
    'fa': 'Persian',
    'sr': 'Serbian',
    'sl': 'Slovenian',
    'sw': 'Swahili',
    'tl': 'Tagalog',
    'ta': 'Tamil',
    'ur': 'Urdu',
    'cy': 'Welsh'
  };

  return language in whisperLanguages;
};
