import type { GetServerSideProps, InferGetServerSidePropsType } from 'next';
import { useRouter } from 'next/router';
import React, { useState, useEffect, useCallback } from 'react';
import { encodePassphrase, generateRoomId, randomString } from '../lib/client-utils';
import styles from '../styles/Home.module.css';

type LogEntry = {
  filename: string;
  content: string;
  timestamp: string;
};

const Home = ({ tabIndex }: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const router = useRouter();
  const [e2ee, setE2ee] = useState(false);
  const [sharedPassphrase, setSharedPassphrase] = useState(randomString(64));
  const [roomName, setRoomName] = useState('');
  const [logs, setLogs] = useState<LogEntry[]>([]);
  const [selectedLog, setSelectedLog] = useState<string | null>(null);

  const startMeeting = () => {
    const roomId = roomName || generateRoomId();
    if (e2ee) {
      router.push(`/rooms/${roomId}#${encodePassphrase(sharedPassphrase)}`);
    } else {
      router.push(`/rooms/${roomId}`);
    }
  };

  const fetchLogs = useCallback(async () => {
    try {
      const res = await fetch('/api/logs');
      const data = await res.json();
      setLogs(data);
    } catch (error) {
      console.error('Failed to fetch logs:', error);
    }
  }, []);

  useEffect(() => {
    fetchLogs();
    router.events.on('routeChangeComplete', fetchLogs);
    return () => {
      router.events.off('routeChangeComplete', fetchLogs);
    };
  }, [router.events, fetchLogs]);

  return (
    <>
      <main className={styles.main} data-lk-theme="default">
        <div className={styles.contentWrapper}>
          {/* Header Section */}
          <div className={styles.header}>
            <img src="/images/cliniverse-home.svg" alt="Cliniverse" />
            <h2>
              Remote meeting app built on Cliniverse,{' '}
              connect in any language, at any place.
            </h2>
          </div>

          {/* Meeting Section */}
          <div className={styles.meetingSection}>
            <div className={styles.tabContent}>
              <p style={{ margin: 0 }}>Try Cliniverse for secure remote telemedicine.</p>
              <input
                id="roomName"
                type="text"
                placeholder="Room Name"
                value={roomName}
                onChange={(ev) => setRoomName(ev.target.value)}
              />
              <button style={{ marginTop: '1rem' }} className="lk-button" onClick={startMeeting}>
                Start Meeting
              </button>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                  <input
                    id="use-e2ee"
                    type="checkbox"
                    checked={e2ee}
                    onChange={(ev) => setE2ee(ev.target.checked)}
                  />
                  <label htmlFor="use-e2ee">Enable end-to-end encryption</label>
                </div>
                {e2ee && (
                  <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                    <label htmlFor="passphrase">Passphrase</label>
                    <input
                      id="passphrase"
                      type="password"
                      value={sharedPassphrase}
                      onChange={(ev) => setSharedPassphrase(ev.target.value)}
                    />
                  </div>
                )}
              </div>
            </div>

            {/* Logs Section */}
            <div className={styles.logViewer}>
              <h3>Recent Meeting Logs</h3>
              <div className={styles.logList}>
                {logs.map(log => (
                  <div key={log.filename} className={styles.logEntry}>
                    <button 
                      onClick={() => setSelectedLog(selectedLog === log.filename ? null : log.filename)}
                      className={styles.logButton}
                    >
                      {log.filename}
                    </button>
                    {selectedLog === log.filename && (
                      <pre className={styles.logContent}>{log.content}</pre>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </main>
      <footer data-lk-theme="default">
        Know more about Cliniverse at{' '}
        <a href="https://axonichealth.com" rel="noopener">
          axonichealth.com
        </a>
      </footer>
    </>
  );
};

export const getServerSideProps: GetServerSideProps<{ tabIndex: number }> = async ({
  query,
  res,
}) => {
  res.setHeader('Cache-Control', 'public, max-age=7200');
  const tabIndex = 0;
  return { props: { tabIndex } };
};

export default Home;
